<template>
  <section class="bg-light p-3 h-100">
    <h2>Questions</h2>
    <hr />
    <article class="mt-3">
      <div
        class="d-flex flex-wrap align-items-stretch justify-content-strat gap-3"
      >
        <div
          class="col cursor-pointer"
          v-for="(item, i) in questions"
          :key="i"
          @click="chooseUpdate(item)"
          :class="{
            'border border-primary border-2': item.id_faq == form.id_faq,
          }"
        >
          <div class="card shadow bg-dark text-light border-0 h-100">
            <div class="card-body">
              <p class="card-text d-flex">
                {{ item.content_faq }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </article>
    <div class="mt-4">
      <h2>Modification</h2>
      <hr />
      <form class="row g-3 mb-4" @submit.prevent="confirm">
        <div class="col-12">
          <label for="inputMessage" class="form-label">Question</label>
          <input
            type="text"
            class="form-control"
            id="inputMessage"
            rows="8"
            style="resize: none"
            v-model="form.title_faq"
          />
          <!-- <div
            v-if="submitted && !$v.form.message_faq.required"
            class="invalid-feedback"
          >
            Veuillez insérer votre message ici
          </div> -->
        </div>

        <div class="col-12">
          <label for="inputMessage" class="form-label">Réponse</label>
          <textarea
            class="form-control"
            id="inputMessage"
            rows="8"
            style="resize: none"
            v-model="form.content_faq"
          ></textarea>
          <!-- <div
            v-if="submitted && !$v.form.message_faq.required"
            class="invalid-feedback"
          >
            Veuillez insérer votre message ici
          </div> -->
        </div>

        <div class="col-12 mb-2 mt-4 d-flex justify-content-between">
          <!-- <button
            v-if="loading.send"
            class="btn btn-primary"
            type="button"
            disabled
          >
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Envoi...
          </button> -->
          <button v-if="loading" class="btn btn-primary" type="button" disabled>
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Envoi...
          </button>
          <button v-else type="submit" class="btn btn-primary">Modifer</button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import { getAllFAQ, updateFAQ } from "../../api/apiFAQ";
import { success, error } from "../../utils/toast";

export default {
  name: "QuestionFAQ",
  components: {},
  data() {
    return {
      loading: false,
      questions: [],
      form: {
        id_faq: 0,
        title_faq: "",
        content_faq: "",
      },
    };
  },
  mounted() {
    getAllFAQ().then((result) => {
      this.questions = result.data;
    });
  },
  methods: {
    chooseUpdate(data) {
      this.form = {
        id_faq: data.id_faq,
        title_faq: data.title_faq,
        content_faq: data.content_faq,
      };
    },
    confirm() {
      if (this.form.id_faq != 0) {
        this.loading = true;
        console.log(this.form);
        updateFAQ(this.form.id_faq, this.form).then((result) => {
          if (result.data.error) {
            error(result.data.error);
          } else {
            getAllFAQ().then((result) => {
              this.questions = result.data;
            });
            success("Modification réussi");
            this.loading = false;
          }
        });
      } else {
        error("Choisissez une question");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
